'use client';

import { useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/navigation';
import { Container, Box, Typography, Button } from '@mui/material';
import NotFoundImage from '@/public/static/404.svg';
export default function NotFound() {
  const router = useRouter();
  useEffect(() => {
    Sentry.captureMessage('404 not found. Potentially a redirect to marketplace issue.');
  }, []);
  return <Container sx={{
    minHeight: '70vh'
  }} data-sentry-element="Container" data-sentry-component="NotFound" data-sentry-source-file="not-found.tsx">
      <Box sx={{
      display: 'flex',
      flexFlow: 'column wrap',
      alignItems: 'center',
      minHeight: '90vh',
      height: '100%'
    }} data-sentry-element="Box" data-sentry-source-file="not-found.tsx">
        <img src={NotFoundImage} alt="404 image" style={{
        marginTop: 80,
        width: '100%',
        maxWidth: 500,
        height: 'auto',
        display: 'block'
      }} />
        <Typography variant="h6" sx={{
        mt: 2,
        fontWeight: 600
      }} data-sentry-element="Typography" data-sentry-source-file="not-found.tsx">
          Oops, 404 Error
        </Typography>
        <Typography sx={{
        mt: 2
      }} data-sentry-element="Typography" data-sentry-source-file="not-found.tsx">
          Looks like this page is lost in automation! Let&apos;s get you back on
          track!
        </Typography>
        <Box sx={{
        mt: 4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '16px'
      }} data-sentry-element="Box" data-sentry-source-file="not-found.tsx">
          <Button variant="outlined" onClick={() => router.back()} sx={{
          color: '#2E2C34',
          borderColor: '#98AAEC',
          textTransform: 'none',
          borderRadius: 0,
          py: 1.5,
          px: 6
        }} data-sentry-element="Button" data-sentry-source-file="not-found.tsx">
            Go back to the previous page
          </Button>
          <Button variant="outlined" href="/" sx={{
          textDecoration: 'none',
          backgroundColor: '#2E2C34',
          borderColor: '#2E2C34',
          color: 'common.white',
          textTransform: 'none',
          borderRadius: 0,
          py: 1.5,
          px: 6,
          '&:hover, &:hover svg': {
            backgroundColor: '#2E2C34',
            borderColor: '#2E2C34',
            color: 'common.white'
          }
        }} data-sentry-element="Button" data-sentry-source-file="not-found.tsx">
            Take me to the homepage
          </Button>
        </Box>
      </Box>
    </Container>;
}